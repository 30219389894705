import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var uploadPicture = function uploadPicture() {
  return import("../common/uploadPicture");
};

export default {
  components: {
    uploadPicture: uploadPicture
  },
  data: function data() {
    return {
      id: this.$route.query.id,
      article: {
        articleTitle: "",
        articleContent: "",
        commentStatus: true,
        recommendStatus: false,
        viewStatus: true,
        password: "",
        tips: "",
        articleCover: "",
        sortId: null,
        labelId: null
      },
      sorts: [],
      labels: [],
      labelsTemp: [],
      rules: {
        articleTitle: [{
          required: true,
          message: '请输入标题',
          trigger: 'change'
        }],
        articleContent: [{
          required: true,
          message: '请输入内容',
          trigger: 'change'
        }],
        commentStatus: [{
          required: true,
          message: '是否启用评论',
          trigger: 'change'
        }],
        recommendStatus: [{
          required: true,
          message: '是否推荐',
          trigger: 'change'
        }],
        viewStatus: [{
          required: true,
          message: '是否可见',
          trigger: 'change'
        }],
        articleCover: [{
          required: true,
          message: '封面',
          trigger: 'change'
        }],
        sortId: [{
          required: true,
          message: '分类',
          trigger: 'change'
        }],
        labelId: [{
          required: true,
          message: '标签',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {},
  watch: {
    'article.sortId': function articleSortId(newVal, oldVal) {
      if (oldVal !== null) {
        this.article.labelId = null;
      }

      if (!this.$common.isEmpty(newVal) && !this.$common.isEmpty(this.labels)) {
        this.labelsTemp = this.labels.filter(function (l) {
          return l.sortId === newVal;
        });
      }
    }
  },
  created: function created() {
    this.getSortAndLabel();
  },
  mounted: function mounted() {},
  methods: {
    imgAdd: function imgAdd(pos, file) {
      var suffix = "";

      if (file.name.lastIndexOf('.') !== -1) {
        suffix = file.name.substring(file.name.lastIndexOf('.'));
      }

      var key = "articlePicture" + "/" + this.$store.state.currentAdmin.username.replace(/[^a-zA-Z]/g, '') + this.$store.state.currentAdmin.id + new Date().getTime() + Math.floor(Math.random() * 1000) + suffix;
      var storeType = localStorage.getItem("defaultStoreType");
      var fd = new FormData();
      fd.append("file", file);
      fd.append("key", key);
      fd.append("relativePath", key);
      fd.append("type", "articlePicture");
      fd.append("storeType", storeType);

      if (storeType === "local") {
        this.saveLocal(pos, fd);
      } else if (storeType === "qiniu") {
        this.saveQiniu(pos, fd);
      }
    },
    saveLocal: function saveLocal(pos, fd) {
      var _this = this;

      this.$http.upload(this.$constant.baseURL + "/resource/upload", fd, true).then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          var url = res.data;

          _this.$refs.md.$img2Url(pos, url);
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    saveQiniu: function saveQiniu(pos, fd) {
      var _this2 = this;

      this.$http.get(this.$constant.baseURL + "/qiniu/getUpToken", {
        key: fd.get("key")
      }, true).then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          fd.append("token", res.data);

          _this2.$http.uploadQiniu(_this2.$constant.qiniuUrl, fd).then(function (res) {
            if (!_this2.$common.isEmpty(res.key)) {
              var url = _this2.$constant.qiniuDownload + res.key;
              var file = fd.get("file");

              _this2.$common.saveResource(_this2, "articlePicture", url, file.size, file.type, "qiniu", true);

              _this2.$refs.md.$img2Url(pos, url);
            }
          }).catch(function (error) {
            _this2.$message({
              message: error.message,
              type: "error"
            });
          });
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    addArticleCover: function addArticleCover(res) {
      this.article.articleCover = res;
    },
    getSortAndLabel: function getSortAndLabel() {
      var _this3 = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/listSortAndLabel").then(function (res) {
        if (!_this3.$common.isEmpty(res.data)) {
          _this3.sorts = res.data.sorts;
          _this3.labels = res.data.labels;

          if (!_this3.$common.isEmpty(_this3.id)) {
            _this3.getArticle();
          }
        }
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    getArticle: function getArticle() {
      var _this4 = this;

      this.$http.get(this.$constant.baseURL + "/admin/article/getArticleById", {
        id: this.id
      }, true).then(function (res) {
        if (!_this4.$common.isEmpty(res.data)) {
          _this4.article = res.data;
        }
      }).catch(function (error) {
        _this4.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    submitForm: function submitForm(formName) {
      var _this5 = this;

      if (this.article.viewStatus === false && this.$common.isEmpty(this.article.password)) {
        this.$message({
          message: "文章不可见时必须输入密码！",
          type: "error"
        });
        return;
      }

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this5.$common.isEmpty(_this5.id)) {
            _this5.saveArticle(_this5.article, "/article/saveArticle");
          } else {
            _this5.article.id = _this5.id;

            _this5.saveArticle(_this5.article, "/article/updateArticle");
          }
        } else {
          _this5.$message({
            message: "请完善必填项！",
            type: "error"
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();

      if (!this.$common.isEmpty(this.id)) {
        this.getArticle();
      }
    },
    saveArticle: function saveArticle(value, url) {
      var _this6 = this;

      this.$confirm('确认保存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this6.$http.post(_this6.$constant.baseURL + url, value, true).then(function (res) {
          _this6.$message({
            message: "保存成功！",
            type: "success"
          });

          _this6.$router.push({
            path: '/postList'
          });
        }).catch(function (error) {
          _this6.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this6.$message({
          type: 'success',
          message: '已取消保存!'
        });
      });
    }
  }
};